.section {
  background-color: var(--color-sidebar-bg);
  padding: 1rem;
  margin-top: 2rem;
  border-radius: 1rem;
}

.section + .section {
  margin-top: 0.5rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.625rem;
}

.titleContent {
  background-color: transparent;
  align-items: center;
  display: inline-flex;
  width: 100%;
  border: 0;
  font: inherit;
  padding: 0;
  cursor: pointer;
}

.titleContent:focus {
  outline: 0;
}

.arrowContainer {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0;
  background-color: var(--color-white);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.arrow {
  font-size: 1.125rem;
  color: var(--color-pink);
  transition: transform 200ms ease-in;
}
.arrowCollapsed {
  transform: rotate(180deg);
}

.description {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.85;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.items {
  display: block;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.hidden {
  display: none;
}

.badge {
  font-size: 0.75rem;
  background-color: var(--color-pink);
  color: var(--color-white);
  padding: 0.1625rem 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.625rem;
}
