.chart {
  margin-left: 25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: calc(100vw - 30rem);
  padding-top: 5vh;
  height: 90vh;
  display: none;
}

.radar {
  z-index: 10;
  pointer-events: none;
  background: transparent;
  padding-left: 5vw;
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.radarHidden {
  opacity: 0;
}

.tooltipContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease-in-out;
}

.tooltip {
  color: white;
  padding: 0.7rem 0.75rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-family: Roboto, sans-serif;
  font-weight: 600;
}

.tooltipArrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid;
  margin-top: 10px;
}

.tooltipActive {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 1024px) {
  .chart {
    display: flex;
  }
}
