.sidebar {
  height: 100%;
  padding: 2.1875rem 2.5rem;
  position: fixed;
  overflow-y: auto;
  background-color: white;
  overflow: overlay;
}

/* width */
.sidebar::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  background: var(--color-gray-50);
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: var(--color-gray-100);
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-150);
}

.title {
  font-size: 2.125rem;
  font-weight: bold;
  margin: 0;
  color: var(--color-blue);
}

.subtitle {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 1rem;
  color: var(--color-blue-900);
}

.loggingButton {
  border: solid 1px var(--color-input-border);
  padding: 0.75rem;
  border-radius: 0.5rem;
  text-decoration: none;
  background: none;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loggingButton span {
  margin-left: 0.25rem;
  font-size: 0.75rem;
  letter-spacing: 0.12rem;
}

.loggingButton:hover {
  cursor: pointer;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.65rem;
}

@media (min-width: 1024px) {
  .sidebar {
    width: 25rem;
  }
}
