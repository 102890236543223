@value colorWhite #ffffff;

@value colorGray50 #f7f7f7;
@value colorGray100 #d6d6d6;
@value colorGray150 #c9c9c9;

@value colorSidebarBg #f8f9fe;
@value colorSidebarBgThreeFourthTransparent rgba(226, 228, 243, 0.75);
@value colorSidebarItemBg #eceef3;

@value colorBlue200 #a5b9cb;
@value colorBlue300 #98aec2;
@value colorBlue400 #424b63;
@value colorBlue #142141;
@value colorBlue900 #0d0c22;

@value colorAppBg #f1f2f6;

@value colorInputBorder #d2d6e0;
@value colorInputPlaceholder #a7bacc;
@value colorInputBorderFocus #6b7aa3;

@value colorCategoryBlue #2d32a0;
@value colorCategoryPink #ff3a64;
@value colorCategoryYellow #ecc050;
@value colorCategoryAquamarine #49beaa;

:root {
  --color-white: colorWhite;
  --color-pink: colorCategoryPink;

  --color-gray-50: colorGray50;
  --color-gray-100: colorGray100;
  --color-gray-150: colorGray150;

  --color-sidebar-bg: colorSidebarBg;
  --color-sidebar-bg-three-fourth-transparent: colorSidebarBgThreeFourthTransparent;
  --color-sidebar-item-bg: colorSidebarItemBg;

  --color-blue-200: colorBlue200;
  --color-blue-300: colorBlue300;
  --color-blue-400: colorBlue400;
  --color-blue: colorBlue;
  --color-blue-900: colorBlue900;

  --color-app-bg: colorAppBg;

  --color-input-border: colorInputBorder;
  --color-input-placeholder: colorInputPlaceholder;
  --color-input-border-focus: colorInputBorderFocus;
}
