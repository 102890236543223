@import-normalize;
@import "./styles/Colors.module.css";

html {
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.circle-title {
  font-family: Inter, sans-serif;
  font-weight: 600;
  pointer-events: none;
  user-select: none;
  text-anchor: middle;
  transition: fill 200ms ease-in, stroke 500ms ease-in 500ms,
    opacity 500ms ease-in;
}

.circle-title-avoid {
  fill: var(--color-blue-200);
  font-size: 0.6875rem;
}

.circle-title-experiment {
  fill: var(--color-blue-300);
  font-size: 0.75rem;
}

.circle-title-sometimes {
  fill: var(--color-blue-400);
  font-size: 0.8125rem;
}

.circle-title-common {
  fill: var(--color-blue);
  font-size: 0.875rem;
}

.circle-div {
  fill: white;
  transition: fill 200ms ease-in;
  pointer-events: auto;
}

.circle-div-container {
  opacity: 1;
}

.circle-div-container.init {
  opacity: 0;
}

.circle-common-container {
  transition: opacity 500ms ease-in-out, background-color 200ms ease-in;
}

.circle-sometimes-container {
  transition: opacity 500ms ease-in-out 250ms, background-color 200ms ease-in;
}

.circle-experiment-container {
  transition: opacity 500ms ease-in-out 500ms, background-color 200ms ease-in;
}

.circle-avoid-container {
  transition: opacity 500ms ease-in-out 750ms, background-color 200ms ease-in;
}

.circle-div:hover,
.circle-div.active {
  fill: #edf6fe;
}

.circle-title {
  opacity: 1;
}

.circle-title.active {
  fill: var(--color-blue);
}

.circle-title.init {
  opacity: 0;
}

.circle-indicator {
  opacity: 0;
  transition: opacity 200ms ease-in;
}

.circle-indicator.active {
  opacity: 0.2;
}

.item {
  pointer-events: auto;
  opacity: 1;
  transition: 500ms ease-in-out;
}

.item-pe-none {
  pointer-events: none;
  visibility: hidden;
}

.item.hidden {
  opacity: 0;
}

.item.item-lighten {
  opacity: 0.2;
  transition-delay: 0;
}

.item:hover {
  cursor: pointer;
}
