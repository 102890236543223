.loader-radar {
  position: absolute;
  z-index: 100;
  width: 480px;
  height: 480px;
  left: 50%;
  top: 50%;
  transform: translateX(-52%) translateY(-53%);
  margin-left: 12.5rem;
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.loader-radar.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.loader-radar div {
  position: absolute;
  border: 16px solid var(--color-sidebar-bg-three-fourth-transparent);
  opacity: 1;
  border-radius: 50%;
  animation: loader-radar 3s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loader-radar div:nth-child(2) {
  animation-delay: -0.5s;
}

.loader-radar div:nth-child(3) {
  animation-delay: -1s;
}

@keyframes loader-radar {
  0% {
    top: 236px;
    left: 236px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 472px;
    height: 472px;
    opacity: 0;
  }
}
