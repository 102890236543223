.item {
  border-radius: 0.5rem;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 2.15;
  padding: 0.6875rem 1rem 0.5625rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
}

.item:hover {
  background-color: var(--color-sidebar-item-bg);
}
