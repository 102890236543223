.teams {
  position: relative;
}

.title {
  margin-bottom: 0.75rem;
  margin-top: 1.625rem;
}

.select {
  position: relative;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: solid 1px var(--color-input-border);
  width: 100%;
  transition: border-color 250ms ease-in-out;
  height: 48px;
  background: transparent;
  padding: 0.625rem 1rem;
  appearance: none;
  outline: none;
}

.arrow {
  font-size: 1.125rem;
  color: var(--color-pink);
  transform: rotate(180deg);
}

.arrowContainer {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5px;
  width: 2rem;
  height: 2rem;
  border: 0;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
