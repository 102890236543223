.search {
  display: inline-flex;
  cursor: text;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  border-radius: 0.5rem;
  border: solid 1px var(--color-input-border);
  padding: 0.5625rem;
  width: 100%;
  transition: border-color 250ms ease-in-out;
}

.search:focus-within {
  border-color: var(--color-input-border-focus);
}

.input {
  margin-left: 0.5625rem;
  border: 0px;
  font-size: 0.875rem;
  line-height: 2;
  color: var(--color-blue);
  outline: none;
  flex: 1;
}

.input::placeholder {
  color: var(--color-input-placeholder);
}

.icon {
  transform: scaleX(-1);
}
